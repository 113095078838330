import React from 'react'

import SEO from '../../components/seo'
import PSAWifi from '../../components/PSAWifi/PSAWifi'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'

const Wifi: React.FC = () => {
  return (
    <div>
      <SEO
        title="Wi-fi Recommended"
        description="Make sure you have a stable internet connection throughout the gameplay"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
      />
      <PSAWifi />
    </div>
  )
}

export default Wifi
